import Vue from "vue";
import TitleMixin from "@/mixins/title";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { dateValidationRule } from "@/helpers/formValidation";
import SpinnerMixin from "@/mixins/spinner";
import { getTime, getToday, addDays, getNameOfWeek } from "@/helpers/dateTimeHelper";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            data: {
                startDateTime: null,
                endDateTime: null,
                hours: []
            },
            nextId: 0,
            title: this.$t("employeeWorkHours.edit.title"),
            workWeekId: this.$route.params.workWeekId,
            employee: {},
            offices: [],
            validationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    startDateTime: {
                        date: [dateValidationRule]
                    },
                    endDateTime: {
                        date: [dateValidationRule],
                        dateAfter: [
                            (args) => {
                                const startTime = args.element.form.querySelector("#startDateTime").ej2_instances[0].value;
                                const endTime = args.element.form.querySelector("#endDateTime")
                                    .ej2_instances[0].value;
                                return startTime < endTime;
                            },
                            this.$t('employeeWorkHours.endDateMustBeGreaterThanStartDate')
                        ],
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getEmployeeId: "account/getEmployeeId"
        }),
        employeeId() {
            if (this.$route.params.employeeId != undefined)
                return this.$route.params.employeeId;
            return this.getEmployeeId;
        }
    },
    methods: {
        getNameOfWeek,
        removeHours(id) {
            /*
            const workHour = this.data.find(workHour => workHour.id == id);
            if (workHour != null) {
              workHour.enabled = false;
              this.addMissingDayOfWeek(workHour.dayOfWeek);
            }
            */
            const workHour = this.data.hours.find(workHour => workHour.id == id);
            this.data.hours = this.data.hours.filter(workHour => workHour.id != id);
            if (workHour != undefined) {
                this.removeValidation(workHour.id);
                this.addMissingDayOfWeek(workHour.dayOfWeek);
            }
        },
        addHours(dayOfWeek) {
            this.nextId--;
            const workHour = {
                id: this.nextId,
                officeId: this.data.officeId,
                dayOfWeek: dayOfWeek,
                startTime: undefined,
                endTime: undefined,
                enabled: true
            };
            this.data.hours.push(workHour);
            this.addValidation(workHour.id);
        },
        addMissingDayOfWeek(dayOfWeek) {
            if (!this.data.hours.some(workHour => workHour.dayOfWeek == dayOfWeek && workHour.enabled))
                this.addHours(dayOfWeek);
        },
        addMissingDayOfWeeks() {
            for (const dayOfWeek of this.getDaysOfWeek())
                this.addMissingDayOfWeek(dayOfWeek);
        },
        getDaysOfWeek() {
            return [1, 2, 3, 4, 5, 6, 0];
        },
        getWorkHoursForDayOfWeek(dayOfWeek) {
            return this.data.hours.filter(workHour => workHour.dayOfWeek == dayOfWeek && workHour.enabled);
        },
        addValidation(id) {
            this.$refs["employee-work-hours-form"].addRules(`startTime_${id}`, {
                required: [
                    function (args) {
                        if (args.value == "") {
                            if (args.element.form.querySelector(`#endTime_${id}`).value != "")
                                return false;
                        }
                        return true;
                    }
                ]
            });
            this.$refs["employee-work-hours-form"].addRules(`endTime_${id}`, {
                required: [
                    function (args) {
                        if (args.value != "")
                            return true;
                        const startTimeValue = args.element.form.querySelector(`#startTime_${id}`).value;
                        return startTimeValue == "";
                    }
                ],
                hourAfter: [
                    (args) => {
                        if (args.value == "")
                            return true;
                        const startTime = args.element.form.querySelector(`#startTime_${id}`).ej2_instances[0].value;
                        const endTime = args.element.form.querySelector(`#endTime_${id}`)
                            .ej2_instances[0].value;
                        return startTime < endTime;
                    },
                    this.$t('employeeWorkHours.endTimeMustBeGreaterThanStartTime')
                ]
            });
        },
        removeValidation(id) {
            this.$refs["employee-work-hours-form"].removeRules(`startTime_${id}`);
            this.$refs["employee-work-hours-form"].removeRules(`endTime_${id}`);
        },
        checkOutsideWorkingHoursAppointments(appointments) {
            return new Promise((resolve, reject) => {
                const dialog = DialogUtility.confirm({
                    title: "",
                    content: this.$t("employeeWorkHours.checkOutsideWorkingHoursAppointments", {
                        count: appointments.length
                    }),
                    showCloseIcon: false,
                    closeOnEscape: false,
                    okButton: {
                        text: "Sì",
                        click: () => {
                            dialog.close();
                            resolve(true);
                        }
                    },
                    cancelButton: {
                        text: "No",
                        click: () => {
                            dialog.close();
                            resolve(false);
                        }
                    },
                    position: {
                        X: "center",
                        Y: "center"
                    }
                });
            });
        },
        async update() {
            let startDateTime = addDays(getToday(), 1);
            if (this.data.startDateTime != undefined && this.data.startDateTime > startDateTime)
                startDateTime = this.data.startDateTime;
            const appointments = [];
            for (const dayOfWeek of this.getDaysOfWeek()) {
                const workingHours = this.getWorkHoursForDayOfWeek(dayOfWeek)
                    .filter(workingHour => workingHour.startTime != undefined && workingHour.endTime != undefined);
                let startTime = getToday();
                let endTime = getToday();
                if (workingHours.length > 0) {
                    startTime = workingHours.reduce(function (prev, curr) {
                        return prev.startTime < curr.startTime ? prev : curr;
                    }).startTime;
                    endTime = workingHours.reduce(function (prev, curr) {
                        return prev.endTime > curr.endTime ? prev : curr;
                    }).endTime;
                }
                const appointmentsForDayOfWeek = await this.$store.dispatch("calendar/employeeOutsideWorkingHours", {
                    employeeId: this.employeeId,
                    officeId: this.data.officeId,
                    dayOfWeek: dayOfWeek,
                    startTime: startTime,
                    endTime: endTime,
                    startDate: startDateTime,
                    endDate: this.data.endDateTime,
                });
                appointments.push(...appointmentsForDayOfWeek);
            }
            let canModify = appointments.length == 0;
            if (!canModify)
                canModify = await this.checkOutsideWorkingHoursAppointments(appointments);
            if (!canModify)
                return false;
            const workWeek = JSON.parse(JSON.stringify(this.data));
            workWeek.hours = workWeek.hours.filter((workHour) => workHour.enabled &&
                workHour.startTime != undefined &&
                workHour.endTime != undefined);
            for (const workHour of workWeek.hours) {
                if (workHour.id <= 0)
                    workHour.id = undefined;
            }
            return this.$store.dispatch("employeeWorkHour/update", {
                employeeId: this.employeeId,
                workWeekId: this.workWeekId,
                employeeWorkWeek: workWeek
            });
        },
        updateSuccess() {
            this.$toast.showSuccessToast(this.$t("employeeWorkHours.edit.toastSuccessTitle"), this.$t("employeeWorkHours.edit.toastSuccessContent", {
                name: `${this.employee.name} ${this.employee.surname}`
            }));
            return this.backToGrid();
        },
        cancel() {
            return this.backToGrid();
        },
        orderWorkHours() {
            const firstDayOfWeek = 1;
            this.data.hours.sort((a, b) => {
                const result = a.dayOfWeek - b.dayOfWeek;
                if (result != 0)
                    return result; // Move day of week
                if (a.id < 0 || b.id < 0)
                    return 0; // Don't move new records
                if (a.startTime == undefined)
                    return 1;
                if (b.startTime == undefined)
                    return -1;
                return getTime(a.startTime) - getTime(b.startTime);
            });
            if (this.data.hours.length > 0) {
                while (this.data.hours[0] != undefined &&
                    this.data.hours[0].dayOfWeek != firstDayOfWeek)
                    this.data.hours.push(this.data.hours.shift());
            }
        },
        backToGrid() {
            if (this.$route.params.employeeId != undefined)
                return this.$router.push(`/employees/${this.employeeId}/work-weeks`);
            return this.$router.push(`/work-weeks`);
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.employee = await this.$store.dispatch("employee/get", {
                id: this.employeeId
            });
            this.offices = await this.$store.dispatch("office/getAll");
            this.data = await this.$store.dispatch("employeeWorkHour/get", {
                employeeId: this.employeeId,
                workWeekId: this.workWeekId
            });
            this.data.hours.forEach(workHour => {
                workHour.enabled = true;
                this.addValidation(workHour.id);
            });
            this.addMissingDayOfWeeks();
            this.orderWorkHours();
            this.title += ` - ${this.employee.name} ${this.employee.surname}`;
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.backToGrid();
        }
    }
});
